import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

import {
  LFBSAuthentication,
  LFBSConst,
  LFBSDataColumn,
  LFBSDataDisplay,
  LFBSUtils,
} from 'lfbs-common-lib'

import { 
  LFBSDataView,
  LFBSPrettyPrintJson,
} from 'lfbs-ui-lib' 

import UIConst from '../../common/UIConst'

import {
  Avatar,
} from '@mui/material'

const Profile = ({appProps}) => {
  let lfbsUtils = new LFBSUtils()
  let lfbsConst = new LFBSConst()
  let uiConst = new UIConst()
  let lfbsAuthentication = new LFBSAuthentication()
  const history = useHistory()

  const [data, setData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [editMode] = useState(lfbsConst.UPDATE)
  const [selectedIndex] = useState(0)

  let lfbsDataDisplay = undefined

  useEffect(() => {
     getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    let userProfile = []
    
    userProfile.push(lfbsAuthentication.getSignedInUser())

    console.log(`userProfile - <${JSON.stringify(userProfile)}>`)

    setData(userProfile)
    setTotalItems(1)
  }

  const handleEditMode = (editMode,selectedIndx) => {
    if(editMode === lfbsConst.READ) {
      history.goBack()
    }
  }

  const handleUpdate = (entity) => {
    let user = data[0]

    user[lfbsConst.PROFILE] = entity
    finishUpdate(user[lfbsConst.PROFILE])
  }
  
  const finishUpdate = (userProfile) => {
    let user = lfbsAuthentication.getSignedInUser()
    
    user.profile = userProfile
    
    lfbsAuthentication.setAppUser(user)
    appProps.signedInUserChanged()
    history.goBack()
  }

  const getInitials = () => {
    let initals = null

    if(appProps.signedInUser) {
      initals = lfbsUtils.getInitials(appProps.signedInUser)      
    }

    return(initals)
  }

  const formatAvatar = (photoUrl) => {
    return(
      <Avatar alt="Signin user" src={photoUrl}>
          {getInitials()}
      </Avatar>
    )
  }

  const getColumnData = (rowIndx,columnAttribute) => {

    if(!data || (rowIndx < 0) || rowIndx > (data.length -1)) {
      return(null)
    }

    let colData = data[rowIndx][columnAttribute]

    switch(columnAttribute){
      case(uiConst.COLUMN_AVATAR):
        colData = formatAvatar(data[rowIndx][uiConst.COLUMN_PHOTO_URL])
        break
      case(uiConst.COLUMN_ROWS_PER_PAGE):
        colData = data[rowIndx][uiConst.PROFILE][columnAttribute]
        break
      case(uiConst.COLUMN_DOC_VERSION_ID):
        colData = data[rowIndx][uiConst.PROFILE][columnAttribute] || ""
        break
      case(uiConst.COLUMN_SHOW_ADMIN_FEATURES):
        colData = data[rowIndx][uiConst.PROFILE][columnAttribute]
        break
      case(uiConst.COLUMN_UI_THEME):
        if(data[rowIndx][uiConst.PROFILE]){
          colData = data[rowIndx][uiConst.PROFILE][columnAttribute]
        }
        break
      default:
        break
    }

    return(colData)
  }

  const getSelectList = (rowIndx,columnAttribute) => {
    if(columnAttribute === uiConst.COLUMN_ROWS_PER_PAGE) {
      return(<>
              { lfbsConst.rowsPerPageOptions.map((rowsPerPage) => (
                  <option key={rowsPerPage} value={rowsPerPage}>
                    {rowsPerPage}
                  </option>
                ))
              }
             </>
            )
        } else if(columnAttribute === uiConst.COLUMN_UI_THEME) {
          return(<>
                    <option key={lfbsConst.UI_THEME_LIGHT}
                      value={lfbsConst.UI_THEME_LIGHT}>
                      {lfbsConst.UI_THEME_LIGHT}
                    </option>
  
                      <option key={lfbsConst.UI_THEME_DARK} 
                        value={lfbsConst.UI_THEME_DARK}>
                        {lfbsConst.UI_THEME_DARK}
                      </option>
                  </>
                )
      } else {
        return (<></>)
    }
  }

  const getLFBSDataDisplay = () => {
    const errorMsg = ""
    let lfbsDataColumns = []

    if(lfbsDataDisplay) {
      return(lfbsDataDisplay)
    }

    lfbsDataColumns.push(new LFBSDataColumn('',uiConst.COLUMN_AVATAR,lfbsConst.COLUMN_TYPE_STRING,false,true,false,true,false,false))
    lfbsDataColumns.push(new LFBSDataColumn('Email',uiConst.COLUMN_EMAIL,lfbsConst.COLUMN_TYPE_EMAIL,false,true,false,true,false,false))
    lfbsDataColumns.push(new LFBSDataColumn('Rows Per Page',uiConst.COLUMN_ROWS_PER_PAGE,lfbsConst.COLUMN_TYPE_STRING,false,true,true,true,true))
    lfbsDataColumns.push(new LFBSDataColumn('UI Theme',uiConst.COLUMN_UI_THEME,lfbsConst.COLUMN_TYPE_SINGLE_SELECT,false,true,true,true,true,true))

    lfbsDataDisplay = new LFBSDataDisplay(lfbsDataColumns,null,data,totalItems,getData,getColumnData,errorMsg,handleEditMode)

    lfbsDataDisplay.createable = false
    lfbsDataDisplay.editable = true
    lfbsDataDisplay.deleteable = false
    lfbsDataDisplay.discoverable = false

    lfbsDataDisplay.searchable = false
    lfbsDataDisplay.paging = false

    lfbsDataDisplay.entity = "Profile"
    lfbsDataDisplay.editMode = editMode
    lfbsDataDisplay.handleUpdate = handleUpdate

    lfbsDataDisplay.selectedIndex = selectedIndex

    lfbsDataDisplay.getSelectListMethod = getSelectList
    
    return(lfbsDataDisplay)
  }

  return (
    <>
      <LFBSDataView appProps={appProps} dataViewProps={getLFBSDataDisplay()} ></LFBSDataView>
  
      <LFBSPrettyPrintJson appProps={appProps} jsonData={getLFBSDataDisplay().lfbsDataColumns}/>
    </>
    )
}

export default Profile
