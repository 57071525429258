import { LFBSSignout } from 'lfbs-ui-lib' 

const Signout = ({appProps}) => {

  return (
    <>
      <LFBSSignout appProps={appProps} signinUrl={"/Signin"}/>
    </>
    )
}

export default Signout;
