import React, { Fragment, useState } from 'react'

import {
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography, 
  Hidden,
  Drawer, 
  Avatar,
  Box,
  Tooltip,
  Menu,
  MenuItem
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@mui/icons-material/Menu'

import HomeIcon from '@mui/icons-material/Home'
import GroupIcon from '@mui/icons-material/Group'
import DataArrayIcon from '@mui/icons-material/DataArray'

import logo from '../../images/lfbs_logo.png'

import {
  LFBSMenuDrawer
} from 'lfbs-ui-lib'

import {
  LFBSAuthentication,
  LFBSUtils,
} from 'lfbs-common-lib'

const drawerWidth = 280
let lfbsUtils = new LFBSUtils()

const userTheme = lfbsUtils.getUserUITheme()

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#222222'
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
    color: (userTheme === "light" ? "black" : "white"),
    backgroundColor: (userTheme === "light" ? "white" : "#222222")
  },
  content: {
    height: "100vh",
    overflow: 'auto',
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  activeAvatar: {
    backgroundColor: theme.palette.primary[theme.palette.type]
  },
  title:{
    textAlign: "start",
    margin: "auto",
  },
  logo: {
    height: "50px",
    width: "50px",     
    paddingRight: "10px"
  }
})

const Layout = ({appProps,children,classes}) => {
  let lfbsAuthentication = new LFBSAuthentication()
  const baseUrl = `${process.env.PUBLIC_URL}/`

  const [anchorElUser, setAnchorElUser] = useState(null)

  let settings = []

  const [mobileOpen,setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const userRoutes = [
    {
      title: "Home",
      url: `${baseUrl}`,
      target: '_self',
      Icon: HomeIcon
    },
    {
      title: "Users",
      url:`${baseUrl}Users`,
      target: '_self',
      Icon: GroupIcon
    },
    {
      title: "Transit Authority Data",
      url:`${baseUrl}TransitAuthorityData`,
      target: '_self',
      Icon: DataArrayIcon
    },
  ]

  const adminRoutes = [
    {
      title: "Users",
      url:`${baseUrl}Users`,
      target: '_self',
      Icon: GroupIcon
    },
  ]

  const getRoutes = () => {
    const routes = []

    userRoutes.forEach(element => {
      routes.push(element)
    })

    if(lfbsAuthentication.isSystemAdmin(appProps.signedInUser)) {  
      adminRoutes.forEach(element => {
        routes.push(element)
      })
    }

    return(routes)
  }

  const getSettingsHREF = (menuOption) => {
    let href = `${baseUrl}${menuOption}`

    if(menuOption.startsWith(`${baseUrl}Profile`)) {
      href += `${baseUrl}Profile`
    }

    return(href)
  }

  const getInitials = () => {
    let initals = null

    if(appProps.signedInUser) {
      initals = lfbsUtils.getInitials(appProps.signedInUser)      
    }

    return(initals)
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  settings = []

  if(lfbsAuthentication.isUserSignedIn()) {
      settings.push(`Profile`)
      settings.push(`Signout`)
  } else {
      settings.push(`Signin` )
  }

  return(
    <Fragment>
      <div className={classes.root}>
        <AppBar position="absolute" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              className={classes.navIconHide}
            >
              <MenuIcon />
            </IconButton>
              <Hidden smDown>
                <img src={logo} alt="LFBS Logo" className={classes.logo}/>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" color="inherit" noWrap className={classes.title}>
                    LFBS Transit Now
                  </Typography>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <img src={logo} alt="LFBS Logo" className={classes.logo}/>
                <Typography variant="h4" color="inherit" noWrap className={classes.title}>
                LFBS Transit Now
                </Typography>
              </Hidden>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Signin user" src={appProps.signedInUser ? appProps.signedInUser.photoUrl : null}>
                    {getInitials()}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu} component="a" 
                    href={getSettingsHREF(setting)}>
                    <Typography>{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
              <LFBSMenuDrawer menuOptions={getRoutes()}/>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <LFBSMenuDrawer menuOptions={getRoutes()}/>
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
      </Fragment>
  )
}

export default withStyles(styles)(Layout)


