import React from 'react'

const Home = ({appProps}) => {
    return (
        <div>
          yo
        </div>
      )
}
 
export default Home