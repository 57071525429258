import './App.css'

import { initializeApp } from "firebase/app"
// https://firebase.google.com/docs/web/setup#available-libraries

import React, { useState } from 'react'

import {
  Route,
  Switch,
  useLocation
} from "react-router-dom"

import {
  LFBSAppProps,
  LFBSAuthentication,
  LFBSConst,
  LFBSUserMessage,
  LFBSUtils,
} from 'lfbs-common-lib'

import {
  LFBSMaybeLoading,
  LFBSNotifyUser,
  LFBSLightTheme,
  LFBSDarkTheme,
} from 'lfbs-ui-lib'

import Layout from './components/Navigation/Layout'
import { ThemeProvider, CssBaseline } from '@mui/material'

import Home from './components/User/Home'
import Profile from './components/User/Profile'
import Signin from './components/User/Signin'
import Signout from './components/User/Signout'

// Initialize Firebase
const firebaseConfig = {apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
                        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
                        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
                        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
                        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
                        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
                        appId: process.env.REACT_APP_FIREBASE_APPID
                       }

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

function App() {

  let location = useLocation
  const lfbsAuthentication = new LFBSAuthentication()
  const lfbsConst = new LFBSConst()
  const lfbsUtils = new LFBSUtils()

  const [lfbsUserMessage,setLFBSUserMessage] = useState(new LFBSUserMessage("", "", lfbsConst.MSG_DURATION, false))
  const [showProgress, setShowProgress] = useState(false)

  const initAppProps = () => {
    const signedInUser = lfbsAuthentication.getSignedInUser()
    let appProp = undefined 
    
    if((signedInUser) && (!signedInUser[lfbsConst.PROFILE])) {
      signedInUser[lfbsConst.PROFILE] = {}
      signedInUser[lfbsConst.PROFILE][lfbsConst.ROWS_PER_PAGE] = lfbsConst.DEFAULT_ROWS_PER_PAGE
      signedInUser[lfbsConst.PROFILE][lfbsConst.UI_THEME] = lfbsConst.UI_THEME_DARK
      lfbsAuthentication.setAppUser(signedInUser)
    }

    appProp = new LFBSAppProps(signedInUser, handleSignedInUserChanged, notifyUser,showProgressChanged,firebaseApp)

    appProp.logLevel = appProp.LOG_LEVEL_DEBUG
    return (appProp)
  }

  const getRoutes = () => {
    const routes = []
    const userRoutes = [
      {"path":"/Signin",
       "auth":true,
       "component": <Signin appProps={appProps} />
      },
      // {"path":"/SignUp",
      //  "auth":true,
      //  "component": <SignUp appProps={appProps} />
      // },
      {"path":"/Signout",
       "auth":true,
       "component": <Signout appProps={appProps} />
      },
      {"path":"/",
        "auth":lfbsAuthentication.isUserSignedIn(),
        "component": <Home appProps={appProps} />
      },
      {"path":"/Home",
       "auth":lfbsAuthentication.isUserSignedIn(),
       "component": <Home appProps={appProps} />
      },
      {"path":"/Profile",
       "auth":lfbsAuthentication.isUserSignedIn(),
       "component": <Profile appProps={appProps} />
      },

    //   // Admin functions 
    //   {"path":"/Users",
    //    "auth":authentication.isUserSignedIn() && 
    //           authentication.isSystemAdmin(appProps.signedInUser),
    //    "component": <UserList appProps={appProps} />     
    //   },
      {"path":"*",
       "auth":true,
       "component": <PageNotFound />     
      },
    ]

    userRoutes.forEach(element => {
      if(element.auth === true) {
        routes.push(element)
      } else {
        // Force path to Signin when not authorized, redirect back to requested URL,
        // once authenticated
        appProps.redirectUrl = location.pathname
        routes.push({"path":element.path,
                     "auth":true,
                     "component": <Signin appProps={appProps} />
                    })
      }
    })

    return(routes)
  }

  const handleSignedInUserChanged = () => {
    setAppProps(initAppProps())
  }

  const notifyUser = (message, severity) => {
    let msgSeverity = severity ? severity : appProps.SEVERITY_INFO
    let duration = (severity === appProps.SEVERITY_ERROR) ? null : lfbsConst.MSG_DURATION
    let msg = new LFBSUserMessage(message,msgSeverity,duration,true)

    setLFBSUserMessage(msg)
  }

  const hideUserMessage = () => {
    setLFBSUserMessage(new LFBSUserMessage("",lfbsUserMessage.severity,lfbsConst.MSG_DURATION,false))
  }

  const showProgressChanged = (requestPending) => {
    setShowProgress(requestPending)
  }

  const [appProps, setAppProps] = useState(initAppProps())

  const PageNotFound = () => {
    return (
      <div>
        <h2>404 Page not found</h2>
      </div>
    )  
  }

  const getUserUITheme = () => {
    return(lfbsUtils.getUserUITheme() === lfbsConst.UI_THEME_LIGHT ? LFBSLightTheme : LFBSDarkTheme )
  }

  return (
    <ThemeProvider theme={getUserUITheme()}>
      <CssBaseline/>
      <Layout appProps={appProps}>
        <div className="content">
          <Switch>
            {getRoutes().map((route) => (
              <Route key={route.path} exact path={route.path}>
              {route.component}
              </Route>
            ))}
          </Switch>
          {(showProgress) && 
            <LFBSMaybeLoading/>
          }
        </div>
      </Layout>

      <LFBSNotifyUser lfbsUserMessage={lfbsUserMessage} hideUserMessage={hideUserMessage}/>
    </ThemeProvider>
  )
}

export default App
