import { LFBSSigninProps } from 'lfbs-common-lib'
import { LFBSSignin } from 'lfbs-ui-lib'
//import HttpRequest from '../../common/api/HttpRequest' 

const Signin = ({appProps}) => {
   const signinFunc = async(appUser) => {
    console.log(`signinFunc() - ${JSON.stringify(appUser)}`)
    return(appUser)
   }

  const signinProps = new LFBSSigninProps(false,true,undefined,signinFunc,appProps.redirectUrl)

  return (
    <>
        <LFBSSignin
            appProps={appProps}
            signinProps={signinProps} >
        </LFBSSignin>
    </>
    )
}

export default Signin
