class UIConst{
    constructor()
    {
        this.COLUMN_AVATAR = "avatar"
        this.COLUMN_TITLE = "title"
        this.COLUMN_SUB_TITLE = "subTitle"
        
        this.COLUMN_ID = "id"
        this.COLUMN_NAME = "name"
        this.COLUMN_FIRST_NAME = "firstName"
        this.COLUMN_MIDDLE_NAME = "middleName"
        this.COLUMN_LAST_NAME = "lastName"
        this.COLUMN_PHOTO_URL = "photoUrl"
        this.COLUMN_ROLES = "roles"
        this.COLUMN_EMAIL = "email"

        this.COLUMN_DESCRIPTION = "description"

        this.COLUMN_ROWS_PER_PAGE = "rowsPerPage"
        this.COLUMN_UI_THEME = "uiTheme"

        this.COLUMN_ROLES = "roles"
        this.COLUMN_SIGNIN_TYPE = "signinType"
        this.COLUMN_LAST_SIGNIN = "lastSignin"
        this.COLUMN_SIGNUP_URL = "signupUrl"
    }
}

export default UIConst
